<template>
  <div class="animated fadeIn">
    <b-card>
      <template v-slot:header>
        <h5>Laporan Stok Barang</h5>
      </template>
      <b-form @submit.prevent="onFilter">
        <div class="row mb-3">
          <div class="col-md-2 col-lg-3">
            <b-form-group label="Nama Barang">
              <b-input v-model="search" placeholder="nama barang"></b-input>
            </b-form-group>
          </div>
          <div class="col-md-3 col-lg-3">
            <b-form-group label="Pilih Cabang">
              <multiselect v-model="cabang" :options="option_cabang" :internal-search="true" :allow-empty="false"
                deselect-label="" select-label="" label="name" track-by="id" placeholder="pilih cabang" />
            </b-form-group>
          </div>
          <div class="col-md-4 col-lg-4">
            <b-form-group label="Filter tanggal">
              <date-range-picker class="w-100" v-model="dateRange" ref="picker"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :minDate="null" :maxDate="new Date()"
                :singleDatePicker="false" :timePicker24Hour="true" :showWeekNumbers="true" :showDropdowns="true"
                :autoApply="true" @select="handleSelectDate">
              </date-range-picker>
            </b-form-group>
          </div>
          <div class="col-md-3 col-lg-2">
            <b-form-group label="Pilih Kategori">
              <multiselect v-model="kategori_barang" :options="option_kategori" :internal-search="true"
                :allow-empty="false" deselect-label="" select-label="" label="name" track-by="id"
                placeholder="pilih kategori" />
            </b-form-group>
          </div>
          <div class="col d-flex justify-content-end justify-content-md-start align-items-center">
            <b-button :disabled="isLoading" type="submit" class="mt-2 mr-2" variant="pgiBtn">
              Search
            </b-button>
            <b-button class="mt-2" variant="pgiBtn" name="resetTableBtn" :disabled="isLoading" @click="onReset()">
              Reset
            </b-button>
          </div>
        </div>
      </b-form>
      <b-table v-if="data_barang" bordered hover show-empty :fields="fields"
        class="mb-3 my-custom-table table-daftar-fpp">
        <template #empty="scope"> Data tidak ditemukan </template>
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner small class="align-middle" variant="secondary"></b-spinner>
            <span class="font-weight-bold"> Harap tunggu...</span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import { mapState } from 'vuex';
export default {
  components: { DateRangePicker },
  data() {
    return {
      role: localStorage.getItem("role"),
      dateRange: { startDate: null, endDate: null },
      startDate: null,
      endDate: null,
      cabang: null,
      kategori_barang: null,
      search: null,
      data_barang: null,
      fields: [
        {
          key: "kategori",
          label: "Kategori Barang",
          sortable: true,
        },
        {
          key: "kode",
          label: "Kode Barang",
          sortable: true,
        },
        {
          key: "nama",
          label: "Nama Barang",
          sortable: true,
        },
        {
          key: "stock",
          label: "Stok",
          sortable: true,
        },
      ],
    }
  },
  methods: {
    handleSelectDate(value) {
      const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
      const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
      this.startDate = startDate
      this.endDate = endDate
    },
    async onFilter() {
      const filter = {
        search: this.search,
        id_kategori: this.kategori_barang ? this.kategori_barang.id : undefined,
        cabang_kode: this.cabang ? this.cabang.kode : undefined,
        startDate: this.startDate,
        endDate: this.endDate,
      }
      this.$store.dispatch("getLaporanBarang", filter)
    },
    onReset() {
      this.search = null
      this.kategori_barang = null
      this.startDate = null
      this.dateRange = { startDate: null, endDate: null }
      this.endDate = null
      this.cabang = null
    }
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
    option_cabang() {
      const response = this.$store.getters.cabangLists;
      const data = response.map(item => {
        return {
          ...item,
          id: item.id,
          nama: item.nama,
        }
      })
      return data;
    },
    option_kategori() {
      return [
        {
          id: 1,
          name: "Kategori 1"
        },
        {
          id: 2,
          name: "Kategori 2"
        },
      ]
    }
  },
  mounted() {
    this.$store.dispatch("getCabang")
  }
}
</script>